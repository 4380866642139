import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { post } from "src/helpers/api_helper";

const GSTServices = () => {
  const [data, setData] = useState<any[]>([]);
  const [taxOptions, setTaxOptions] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const resp = await post(
        process.env.REACT_APP_API_URL + "/api/gst_services_list",
        {}
      );
      if (resp.success) {
        setTaxOptions(resp.taxdata);
        setData(resp.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleUpdate = async (rowId: number, field: string, value: any) => {
    const updatedData = data.map(item =>
      item.id === rowId ? { ...item, [field]: value } : item
    );
    setData(updatedData);

    try {
      const payload = { id: rowId, [field]: value };
      const response = await post(
        process.env.REACT_APP_API_URL + "/api/update_gst_service",
        payload
      );
      if (response.success) {
        fetchData();
      }
    } catch (error) {
      console.error("Error updating data:", error);
      fetchData();
    }
  };

  const PriceEditor = ({
    value,
    row,
    name,
    onSave,
  }: {
    value: number;
    row: any;
    name: string;
    onSave: (newValue: any) => void;
  }) => {
    const [tempValue, setTempValue] = useState(value);
    const [isEditable, setIsEditable] = useState(false);

    const handleDoubleClick = () => {
      setIsEditable(true);
    };

    const handleBlur = () => {
      setIsEditable(false);
    };

    const handleChange = (e: any) => {
      setTempValue(e.target.value);
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        onSave(tempValue);
        setIsEditable(false);
      }
    };

    return (
      <div onDoubleClick={handleDoubleClick}>
        {isEditable ? (
          <input
            type="number"
            value={tempValue}
            className="form-control w-50"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
          />
        ) : (
          <span>{name}{value}</span>
        )}
      </div>
    );
  };


  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "",
      text: "SL No",
      formatter: (cellContent: any, row: any, rowIndex: number) => (
        <>{rowIndex + 1}</>
      ),
      editable: false,
    },
    {
      dataField: "service_name",
      text: "Service Name",
      editable: false,
    },
    {
      dataField: "amount",
      text: "Price",
      formatter: (cellContent: any, row: any) => (
        <>
          <PriceEditor
            value={row.amount}
            row={row}
            name={row.id === 1 ? "Monthly : " : ""}
            onSave={newValue => handleUpdate(row.id, "amount", newValue)}
          />
          {row.id === 1 && (
            <PriceEditor
              value={row.amount_2}
              row={row}
              name={"Quarterly : "}
              onSave={newValue => handleUpdate(row.id, "amount_2", newValue)}
            />
          )}
        </>
      ),
    },
    {
      dataField: "tax_per",
      text: "Tax",
      editable: false,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>GST Services</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="GST Services" breadcrumbItem="GST Services" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={data}
                        columns={columns}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GSTServices;
