import React, { useEffect, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Row,
  Table,
  UncontrolledAlert,
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { get, getAccessToken, post } from "src/helpers/api_helper";
import axios from "axios";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getMonthName, getQuarterly } from "src/helpers/common";

interface Props {
  modal_is_open: boolean;
  closeModal: any;
  data: any;
}
const Details = (props: Props) => {
  const [isHideSuccessMgs, setIsHideSuccessMgs] = useState(true);
  const [isHideErrorMgs, setIsHideErrorMgs] = useState(true);
  const [errorMgs, setErrorMgs] = useState(
    "Somthing went wrong, Please try again"
  );
  const [isModalProgress, setIsModalProgress] = useState(false);
  const [statusValue, setStatusValue] = useState(props.data.status);
  const [gstReturnId, setGstReturnId] = useState<number>(1);
  const [csvData, setCsvData] = useState<any>([]);
  const [pdfData, setPdfData] = useState<any>([]);
  const [expCsvData, setExpCsvData] = useState<any>([]);
  const [expPdfData, setExpPdfData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const csvHeaders = [
    { label: "Invoice Date", key: "invoice_date" },
    { label: "Invoice Number", key: "invoice_no" },
    { label: "Customer Billing GSTIN", key: "gstin" },
    { label: "State Place of Supply", key: "place_state" },
    { label: "HSN/SAC", key: "hsn_or_sac" },
    { label: "Item Taxable Value", key: "taxableValue" },
    { label: "GST Tax Rate", key: "tax_per" },
    { label: "Total Transcation Value", key: "amount" },
    { label: "Invoice Type", key: "invoice_type" },
  ];
  const pdfHeader = [
    [
      "Invoice Date",
      "Invoice Number",
      "Customer Billing GSTIN",
      "State Place of Supply",
      "HSN/SAC",
      "Item Taxable Value",
      "GST Tax Rate",
      "Total Transcation Value",
      "Invoice Type",
    ],
  ];
  const expCsvHeaders = [
    { label: "Bill date", key: "invoice_date" },
    { label: "Bill Number", key: "invoice_no" },
    { label: "Vendor Billing GSTIN", key: "gstin" },
    { label: "State Place of Supply", key: "place_state" },
    { label: "Taxable Value", key: "taxableValue" },
    { label: "Total Value", key: "amount" },
    { label: "Invoice Type", key: "invoice_type" },
  ];
  const expPdfHeader = [
    [
      "Invoice Date",
      "Bill Number",
      "Vendor Billing GSTIN",
      "State Place of Supply",
      "Taxable Value",
      "Total Value",
      "Invoice Type"
    ],
  ];
  const padLeadingZeros = (num: number, size: number) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };
  const sendGetRequest = () => {
    get_invoice_data();
    get_purchase_data();
  };
  const get_invoice_data = async () => {
    try {
      data["id"] = props.data.id;
      data["companyId"] = props.data.company_id;
      data["tax_period"] = props.data.tax_period;
      data["year"] = props.data.year;
      data["instance"] = props.data.instance_id;
      const resp = await post(
        process.env.REACT_APP_API_URL + "/api/sales_gstr1",
        data
      );
      if (resp.success) {
        setData(resp.data);
        let csvData: any = [];
        let pdfData: any = [];
        resp.data.map((report: any) => {
          let invoice_type = "";
          if (report.invoice_type === 1) {
            invoice_type = "B2B Invoice";
          } else if (report.invoice_type === 2) {
            invoice_type = "B2C Invoice";
          } else if (report.invoice_type === 3) {
            invoice_type = "Export Invoice";
          } else {
            invoice_type = " ";
          }
          let taxableValue = report.amount - report.tax_amt;
          csvData.push({
            invoice_date: moment(report.invoice_date).format("YYYY-MM-DD"),
            invoice_no: report.prefix
              ? report.prefix + report.invoice_no
              : "INV" + report.invoice_no,
            gstin: report.gstin,
            place_state: report.place_state,
            hsn_or_sac: report.hsn_or_sac,
            taxableValue: report.taxable_amount,
            tax_per: `${report.tax_per}%`,
            amount: report.total_amount,
            invoice_type: invoice_type,
          });
          pdfData.push([
            moment(report.invoice_date).format("YYYY-MM-DD"),
            report.prefix
              ? report.prefix + report.invoice_no
              : "INV" + report.invoice_no,
            report.gstin,
            report.place_state,
            report.hsn_or_sac,
            report.taxable_amount,
            `${report.tax_per}%`,
            report.total_amount,
            invoice_type,
          ]);
        });
        setCsvData(csvData);
        setPdfData(pdfData);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const currentMonth = new Date(props.data.year, props.data.tax_period);
  const previousMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() - 1
  );
  const getQuarterlyDates = (
    period_frequency: any,
    year: any,
    tax_period: any
  ) => {
    if (period_frequency == 2) {
      const [startYear, endYear] = year.split("-").map(Number);
      let start_date, end_date;
      if (tax_period === 1) {
        start_date = moment(`${startYear}-04-01`).format("YYYY-MM-DD");
        end_date = moment(`${startYear}-06-30`).format("YYYY-MM-DD");
      } else if (tax_period === 2) {
        start_date = moment(`${startYear}-07-01`).format("YYYY-MM-DD");
        end_date = moment(`${startYear}-09-30`).format("YYYY-MM-DD");
      } else if (tax_period === 3) {
        start_date = moment(`${startYear}-10-01`).format("YYYY-MM-DD");
        end_date = moment(`${startYear}-12-31`).format("YYYY-MM-DD");
      } else if (tax_period === 4) {
        start_date = moment(`${endYear}-01-01`).format("YYYY-MM-DD");
        end_date = moment(`${endYear}-03-31`).format("YYYY-MM-DD");
      }
      return { start_date, end_date };
    } else {
      let startOfMonth = new Date(
        previousMonth.getFullYear(),
        previousMonth.getMonth(),
        1
      );
      let endOfMonth = new Date(
        previousMonth.getFullYear(),
        previousMonth.getMonth() + 1,
        0
      );
      let start_date = startOfMonth
        ? moment(startOfMonth).format("YYYY-MM-DD")
        : null;
      let end_date = endOfMonth
        ? moment(endOfMonth).format("YYYY-MM-DD")
        : null;

      return { start_date, end_date };
    }
  };
  const { start_date, end_date } = getQuarterlyDates(
    props.data.period_frequency,
    props.data.year,
    props.data.tax_period
  );
  const get_purchase_data = async () => {
    try {
      data["companyId"] = props.data.company_id;
      data["start_date"] = start_date;
      data["end_date"] = end_date;
      data["instance"] = props.data.instance_id;
      const resp = await post(
        process.env.REACT_APP_API_URL + "/api/purchase_gst",
        data
      );
      if (resp.success) {
        setData(resp.data);
        let csvData: any = [];
        let pdfData: any = [];
        resp.data.map((report: any) => {
          csvData.push({
            invoice_date: moment(report.invoice_date).format("YYYY-MM-DD"),
            invoice_no: report.invoice_no,
            gstin: report.gstin,
            place_state: report.place_state,
            taxableValue: report.taxable_amount,
            amount: report.amount,
          });
          pdfData.push([
            moment(report.invoice_date).format("YYYY-MM-DD"),
            report.invoice_no,
            report.gstin,
            report.place_state,
            report.taxable_amount,
            report.amount,
          ]);
        });
        setExpCsvData(csvData);
        setExpPdfData(pdfData);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (props.data.service_id == gstReturnId) sendGetRequest();
  }, [props.data.id]);
  const downlaodPdf = () => {
    const doc: any = new jsPDF();
    autoTable(doc, {
      head: pdfHeader,
      body: pdfData,
    });
    doc.save("sales_gstr1_report.pdf");
  };
  const colPeriod = (
    tax_period: any,
    period_frequency: any,
    year: any,
    service: any
  ) => {
    if (service == 1) {
      if (period_frequency == 2) {
        return year + " " + getQuarterly(tax_period);
      } else {
        return getMonthName(tax_period) + " " + year;
      }
    } else if (service == 3) {
      return year;
    } else {
      return <div>--</div>;
    }
  };
  const expDownlaodPdf = () => {
    const doc: any = new jsPDF();
    autoTable(doc, {
      head: expPdfHeader,
      body: expPdfData,
    });
    doc.save("purchase_report.pdf");
  };
  return (
    <Modal isOpen={props.modal_is_open} className={"md-700"}>
      <AvForm encType="multipart/formData" id="ticketForm">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Compliance Tickets Details
          </h5>
          <button
            type="button"
            onClick={() => props.closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            id="closeButton"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <UncontrolledAlert hidden={isHideSuccessMgs} color="success">
            Ticket Details Saved successfully
          </UncontrolledAlert>

          <UncontrolledAlert hidden={isHideErrorMgs} color="danger">
            {errorMgs}
          </UncontrolledAlert>
          <Card>
            <CardHeader>
              <CardTitle className="mb-0">Details</CardTitle>
            </CardHeader>
            <CardBody>
              <div>
                <div className="pb-1">
                  <Row>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-14">Company Name</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">{props.data.company_name}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-15">Ticket No</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">
                              RTN{padLeadingZeros(props.data.order_no, 5)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-15">FY- Month</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">
                              {colPeriod(
                                props.data.tax_period,
                                props.data.period_frequency,
                                props.data.year,
                                props.data.service_id
                              )}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-14">GSTIN</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">{props.data.gstin}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-15">Email</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">{props.data.email}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-15">Phone</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">{props.data.phone}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="mb-0">Payment Details</CardTitle>
            </CardHeader>
            <CardBody>
              <div>
                <div className="pb-1">
                  <div className="table-responsive my-3">
                    <Table className="align-middle mb-0">
                      <thead>
                        <tr>
                          <th>Payment Date</th>
                          <th>Payment Id</th>
                          <th>Payment Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {moment(props.data.payment_date).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                          <td>{props.data.razorpay_payment_id}</td>
                          <td>{props.data.payment_amount}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div>
                <div className="pb-1">
                  {props.data.service_id == gstReturnId ? (
                    <>
                      <Row>
                        <Col lg={3}>
                          <h5 className="font-size-14 my-2">
                            Invoices Exports
                          </h5>
                          <div className="table-button my-2">
                            <CSVLink
                              data={csvData}
                              headers={csvHeaders}
                              filename={"sales_gstr1_report.csv"}
                              className="btn tbl-btn"
                            >
                              <i className="fas fa-file-csv"></i>
                            </CSVLink>
                            <Link
                              to="#"
                              className="btn tbl-btn mx-2"
                              style={{ color: "red" }}
                              onClick={() => downlaodPdf()}
                            >
                              <i className="fas fa-file-pdf"></i>
                            </Link>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <h5 className="font-size-14 my-2">
                            Purchase Exports
                          </h5>
                          <div className="table-button my-2">
                            <CSVLink
                              data={expCsvData}
                              headers={expCsvHeaders}
                              filename={"purchase_report.csv"}
                              className="btn tbl-btn"
                            >
                              <i className="fas fa-file-csv"></i>
                            </CSVLink>
                            <Link
                              to="#"
                              className="btn tbl-btn mx-2"
                              style={{ color: "red" }}
                              onClick={() => expDownlaodPdf()}
                            >
                              <i className="fas fa-file-pdf"></i>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => props.closeModal()}
          >
            Close
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

export default Details;
