import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import Grid from "src/pages/Coupon/Datatable";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import moment from "moment";
import Details from "./modal";

const Coupon = () => {
  const [modal_is_open, set_modal_is_open] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [couponSave, setCouponSave] = useState<string>("");

  const openModal = (data: any) => {
    setModalData(data);
    set_modal_is_open(true);
  };
  const closeModal = () => {
    set_modal_is_open(false);
  };

  const handleButtonClick = (couponSaveStatus: any) => {
    setCouponSave(couponSaveStatus);
  };

  const defaultSorted: any = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ];

  // Column data
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      hidden: true,
    },

    {
      dataField: "serial_number",
      text: "SL No",
      sort: true,
    },
    {
      dataField: "coupon_name",
      text: "Coupon Name ",
      sort: true,
    },

    {
      dataField: "coupon_value",
      text: "Value",
      sort: true,
      formatter: (cellContent: any, row: any) => {
        const formattedValue = row.is_percentage
          ? parseFloat(cellContent).toFixed() + "%"
          : parseFloat(cellContent).toString();
        return formattedValue;
      },
    },
    {
      dataField: "created_at",
      text: "Created Date",
      sort: true,
      order: "desc",
      formatter: (cellContent: any, row: any) => {
        return <>{moment(cellContent).format("YYYY-MM-DD")}</>;
      },
    },
    {
      dataField: "expiry_date",
      text: "Expiry Date",
      sort: true,
      formatter: (cellContent: any, row: any) => {
        return <>{moment(cellContent).format("YYYY-MM-DD")}</>;
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Coupon</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Coupon" breadcrumbItem="Coupon" />
          <Row>
            <Row className="flex-row-reverse mb-2">
              <Button
                className="btn btn-primary"
                style={{ width: "fit-content", backgroundColor: "green" }}
                onClick={openModal}
              >
                Create Coupon
              </Button>
            </Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Grid
                        couponSave={couponSave}
                        link="list_grid_all"
                        columns={columns}
                        defaultSorted={defaultSorted}
                        refresh={modal_is_open}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {modal_is_open && (
        <Details
          modal_is_open={modal_is_open}
          closeModal={closeModal}
          data={modalData}
          onButtonClick={handleButtonClick}
        />
      )}
    </React.Fragment>
  );
};

export default Coupon;
