import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalFooter, Row, Table } from "reactstrap";
import moment from "moment";
import { post } from "src/helpers/api_helper";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { number_format } from "src/helpers/common";

interface Props {
  isOpen: boolean;
  tax_period: any;
  year: any;
  order_id: string;
  instance: string;
  closeModal: () => void;
  refresh: () => void;
}
type GroupedData = {
  B2B: {
    totalTaxableAmount: number;
    length: number;
    totalIGST: number;
    totalSGST: number;
    totalCGST: number;
  };
  B2C: {
    totalTaxableAmount: number;
    length: number;
    totalIGST: number;
    totalSGST: number;
    totalCGST: number;
  };
  Export: {
    totalTaxableAmount: number;
    length: number;
    totalIGST: number;
    totalSGST: number;
    totalCGST: number;
  };
};
const GstReturnSubmit = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [mouseTab, setmouseTab] = useState(0);
  const [data, setData] = useState<Array<any>>([]);
  const [allData, setAllData] = useState<Array<any>>([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [activeButton, setActiveButton] = useState("SubmitedInvoiceList");
  const currentMonth = new Date(props.year, props.tax_period - 1);
  const previousMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() - 1
  );
  const startOfMonth = new Date(
    previousMonth.getFullYear(),
    previousMonth.getMonth(),
    1
  );
  const endOfMonth = new Date(
    previousMonth.getFullYear(),
    previousMonth.getMonth() + 1,
    0
  );
  const start_date = startOfMonth
    ? moment(startOfMonth).format("YYYY-MM-DD")
    : null;
  const end_date = endOfMonth ? moment(endOfMonth).format("YYYY-MM-DD") : null;

  useEffect(() => {
    if (props.isOpen) {
      fetchData();
    }
  }, [props.isOpen]);

  const fetchData = async () => {
    try {
      const postValues = {
        startDate: start_date,
        endDate: end_date,
        id: props.order_id,
        instance: props.instance,
        year: props.year,
        tax_period: props.tax_period,
      };
      const resp = await post(
        process.env.REACT_APP_API_URL + `/api/invoice_list`,
        postValues
      );
      const resp1 = await post(
        process.env.REACT_APP_API_URL + `/api/invoice_all_list`,
        postValues
      );
      if (resp.success) {
        setData(resp.data.statements);
        setIsSubmit(true);
      }
      if (resp1.success) {
        setAllData(resp1.data.statements);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const MouseEnter = (tab: any) => {
    setmouseTab(tab);
  };
  const MouseLeave = () => {
    setmouseTab(0);
  };
  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };
  const filteredData = data.filter(
    statement =>
      statement.invoice_no
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      (typeof statement.customer_name === "string" &&
        statement.customer_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()))
  );
  const filteredAllData = allData.filter(
    statement =>
      statement.invoice_no
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      (typeof statement.customer_name === "string" &&
        statement.customer_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()))
  );
  const selectedtotalTaxAmount = data.reduce(
    (sum, item) => sum + Number(item.total_tax_amount),
    0
  );
  const selectedgrossTotalBase = data.reduce(
    (sum, item) => sum + Number(item.gross_total_base),
    0
  );
  const selectedtotalAmount = selectedtotalTaxAmount + selectedgrossTotalBase;
  const totalTaxAmount = allData.reduce(
    (sum, item) => sum + Number(item.total_tax_amount),
    0
  );
  const grossTotalBase = allData.reduce(
    (sum, item) => sum + Number(item.gross_total_base),
    0
  );
  const totalAmount = totalTaxAmount + grossTotalBase;
  const calculateTaxData = (filteredData: any[]): GroupedData => {
    const groupedData: GroupedData = {
      B2B: {
        totalTaxableAmount: 0,
        length: 0,
        totalIGST: 0,
        totalSGST: 0,
        totalCGST: 0,
      },
      B2C: {
        totalTaxableAmount: 0,
        length: 0,
        totalIGST: 0,
        totalSGST: 0,
        totalCGST: 0,
      },
      Export: {
        totalTaxableAmount: 0,
        length: 0,
        totalIGST: 0,
        totalSGST: 0,
        totalCGST: 0,
      },
    };
    filteredData.forEach((invoice: any) => {
      if (invoice.customer_currency !== "INR") {
        groupedData.Export.totalTaxableAmount += parseFloat(
          invoice.total_taxable_amount
        );
        groupedData.Export.length += 1;
        groupedData.Export.totalIGST += parseFloat(invoice.total_igst);
        groupedData.Export.totalSGST += parseFloat(invoice.total_sgst);
        groupedData.Export.totalCGST += parseFloat(invoice.total_cgst);
      } else if (invoice.cust_gst_number) {
        groupedData.B2B.totalTaxableAmount += parseFloat(
          invoice.total_taxable_amount
        );
        groupedData.B2B.length += 1;
        groupedData.B2B.totalIGST += parseFloat(invoice.total_igst);
        groupedData.B2B.totalSGST += parseFloat(invoice.total_sgst);
        groupedData.B2B.totalCGST += parseFloat(invoice.total_cgst);
      } else {
        groupedData.B2C.totalTaxableAmount += parseFloat(
          invoice.total_taxable_amount
        );
        groupedData.B2C.length += 1;
        groupedData.B2C.totalIGST += parseFloat(invoice.total_igst);
        groupedData.B2C.totalSGST += parseFloat(invoice.total_sgst);
        groupedData.B2C.totalCGST += parseFloat(invoice.total_cgst);
      }
    });

    return groupedData;
  };

  const calculateTotalData = (groupedData: any) => {
    const totalData = {
      totalIGST: 0,
      totalSGST: 0,
      totalCGST: 0,
      totalTaxableAmount: 0,
      length: 0,
    };

    Object.keys(groupedData).forEach(category => {
      totalData.length += groupedData[category].length;
      totalData.totalIGST += groupedData[category].totalIGST;
      totalData.totalSGST += groupedData[category].totalSGST;
      totalData.totalCGST += groupedData[category].totalCGST;
      totalData.totalTaxableAmount += groupedData[category].totalTaxableAmount;
    });

    return totalData;
  };
  const groupedData = calculateTaxData(filteredData);
  const totalData = calculateTotalData(groupedData);
  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="md-1000 ledger-modal modal-dialog-centered modal-dialog-scrollable h-100 my-0"
      >
        {/* <div className="modal-header d-block ledger-header border-0 justify-content-center pb-0">
          <Row className="pb-2">
            <Col lg={12}>
              <h5 className="modal-title text-center mt-0 fw-normal">
                Invoice List
              </h5>
            </Col>
            <Col lg={12}>
              <div className="text-center" style={{ fontSize: 20 }}>
                {getMonthName(props.month)} - {props.year}
              </div>
            </Col>
            <div className="offset-md-3 col-md-6 d-flex justify-content-center">
              <div className="input-group w-75">
                <input
                  type="text"
                  placeholder="Search by Invoice Number or Customer Name"
                  className="form-control p-2"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
                <span className="input-group-text">
                  <i className="fi fi-rr-search"></i>
                </span>
              </div>
            </div>
          </Row>
        </div> */}
        <div className="row text-center border-bottom">
          <span className="font-size-18 text-primary my-1">GST Return</span>
        </div>
        <div className="row tab-head cstm-tab p-1">
          <Col className="d-flex justify-content-between">
            <Button
              className="tab-button"
              style={{
                backgroundColor:
                  activeButton === "SubmitedInvoiceList"
                    ? "#10a37f"
                    : "#F2F2F2",
                color:
                  activeButton === "SubmitedInvoiceList" ? "#F2F2F2" : "#000",
              }}
              onClick={() => handleButtonClick("SubmitedInvoiceList")}
            >
              Submited Invoice List
              <label className="icon-container">
                <i
                  className="bx bx-message-square-dots comment_icon"
                  onMouseEnter={() => MouseEnter(2)}
                  onMouseLeave={MouseLeave}
                ></i>
                {mouseTab === 2 && (
                  <div
                    className="popup-order-content"
                    onMouseEnter={() => MouseEnter(2)}
                    onMouseLeave={MouseLeave}
                  >
                    <table className="popup-order-table">
                      <tbody>
                        <tr>
                          <td className="fixed-width">Taxable Amount</td>
                          <td className="px-1"> :</td>
                          <td className="text-end">
                            {number_format(selectedgrossTotalBase)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fixed-width">GST Payable</td>
                          <td className="px-1">:</td>
                          <td className="text-end">
                            {number_format(selectedtotalTaxAmount)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fixed-width">Total</td>
                          <td className="px-1">:</td>
                          <td className="text-end">
                            {number_format(selectedtotalAmount)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </label>
            </Button>
            <Button
              className="tab-button"
              style={{
                backgroundColor:
                  activeButton === "InvoiceDetail" ? "#10a37f" : "#F2F2F2",
                color: activeButton === "InvoiceDetail" ? "#F2F2F2" : "#000",
              }}
              onClick={() => handleButtonClick("InvoiceDetail")}
            >
              Summary
            </Button>
            <Button
              className="tab-button"
              style={{
                backgroundColor:
                  activeButton === "AllInvoiceList" ? "#10a37f" : "#F2F2F2",
                color: activeButton === "AllInvoiceList" ? "#F2F2F2" : "#000",
              }}
              onClick={() => handleButtonClick("AllInvoiceList")}
            >
              All Invoice List
              <label className="icon-container">
                <i
                  className="bx bx-message-square-dots comment_icon"
                  onMouseEnter={() => MouseEnter(1)}
                  onMouseLeave={MouseLeave}
                ></i>
                {mouseTab === 1 && (
                  <div
                    className="popup-order-content"
                    onMouseEnter={() => MouseEnter(1)}
                    onMouseLeave={MouseLeave}
                  >
                    <table className="popup-order-table">
                      <tbody>
                        <tr>
                          <td className="fixed-width">Taxable Amount</td>
                          <td className="px-1"> :</td>
                          <td className="text-end">
                            {number_format(grossTotalBase)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fixed-width">GST Payable</td>
                          <td className="px-1">:</td>
                          <td className="text-end">
                            {number_format(totalTaxAmount)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fixed-width">Total</td>
                          <td className="px-1">:</td>
                          <td className="text-end">
                            {number_format(totalAmount)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </label>
            </Button>
          </Col>
        </div>
        {activeButton == "AllInvoiceList" && (
          <>
            <div className="modal-body pt-0">
              <div className="mb-3">
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="form-inline">
                        {isSubmit && (
                          <Row>
                            <Col lg={12}>
                              <Table
                                className="align-middle mb-0 ledger-table"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th style={{ width: "110px" }}>Date</th>
                                    <th>Invoice Number</th>
                                    <th>Customer name</th>
                                    <th className="text-center">GST Amount</th>
                                    <th className="text-center">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredAllData.map(statement => (
                                    <tr key={statement.id}>
                                      <td>{statement.invoice_date}</td>
                                      <td>
                                        {statement.prefix}
                                        {statement.invoice_no}
                                      </td>
                                      <td>{statement.customer_name}</td>
                                      <td className="text-end">
                                        {number_format(
                                          statement.total_tax_amount
                                        )}
                                      </td>
                                      <td className="text-end">
                                        {number_format(
                                          statement.gross_total_base
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              <Button
                type="button"
                className="bmt-btn-close itemsubmit mw-100"
                style={{ padding: "5px 25px" }}
                onClick={() => props.closeModal()}
              >
                Close
              </Button>
              <Button
                type="button"
                style={{ padding: "5px 25px" }}
                className="bmt-btn-submit itemsubmit mw-100"
                onClick={() => handleButtonClick("InvoiceDetail")}
              >
                Back
              </Button>
            </ModalFooter>

          </>
        )}
        {activeButton == "InvoiceDetail" && (
          <>
            <div className="modal-body pt-0">
              <div className="mb-3">
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="form-inline">
                        {isSubmit && (
                          <Row>
                            <Col lg={12}>
                              <Table
                                className="align-middle mb-0 ledger-table"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th>Description</th>
                                    <th>Invoice Count</th>
                                    <th>Taxable Amount</th>
                                    <th>IGST</th>
                                    <th>CGST</th>
                                    <th>SGST</th>
                                    <th>Total GST</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(groupedData).map(
                                    ([category, group]) => {
                                      return (
                                        <tr key={category}>
                                          <td>{category}</td>
                                          <td>{group.length}</td>
                                          <td style={{ textAlign: "right" }}>
                                            {number_format(parseFloat(group.totalTaxableAmount.toFixed(
                                              2
                                            )))}
                                          </td>
                                          <td style={{ textAlign: "right" }}>{number_format(parseFloat(group.totalIGST.toFixed(2)))}</td>
                                          <td style={{ textAlign: "right" }}>{number_format(parseFloat(group.totalCGST.toFixed(2)))}</td>
                                          <td style={{ textAlign: "right" }}>{number_format(parseFloat(group.totalSGST.toFixed(2)))}</td>
                                          <td style={{ textAlign: "right" }}>
                                            {number_format(parseFloat((
                                              group.totalIGST +
                                              group.totalSGST +
                                              group.totalCGST
                                            ).toFixed(2)))}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                  <tr>
                                    <td>
                                      <strong>Total</strong>
                                    </td>
                                    <td>{totalData.length}</td>
                                    <td style={{ textAlign: "right" }}>
                                      {number_format(parseFloat(totalData.totalTaxableAmount.toFixed(2)))}
                                    </td>
                                    <td style={{ textAlign: "right" }}>{number_format(parseFloat(totalData.totalIGST.toFixed(2)))}</td>
                                    <td style={{ textAlign: "right" }}>{number_format(parseFloat(totalData.totalCGST.toFixed(2)))}</td>
                                    <td style={{ textAlign: "right" }}>{number_format(parseFloat(totalData.totalSGST.toFixed(2)))}</td>
                                    <td style={{ textAlign: "right" }}>
                                      {number_format(parseFloat((
                                        totalData.totalIGST +
                                        totalData.totalSGST +
                                        totalData.totalCGST
                                      ).toFixed(2)))}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              <Button
                className="bmt-btn-close itemsubmit"
                style={{ padding: "5px 25px" }}
                onClick={() => handleButtonClick("SubmitedInvoiceList")}
              >
                Back
              </Button>
              <Button
                type="button"
                style={{ padding: "5px 25px" }}
                className="bmt-btn-submit itemsubmit me-1"
                onClick={() => handleButtonClick("AllInvoiceList")}
              >
                Next
              </Button>
            </ModalFooter>
          </>
        )}
        {activeButton == "SubmitedInvoiceList" && (
          <>
            <div className="modal-body pt-0">
              <div className="mb-3">
                <Row>
                  <Col lg={12}>
                    <Table
                      className="align-middle mb-0 ledger-table mt-1"
                      style={{
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "110px" }}>Date</th>
                          <th>Invoice Number</th>
                          <th>Customer name</th>
                          <th className="text-center">GST Amount</th>
                          <th className="text-center">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map(statement => (
                          <tr key={statement.id}>
                            <td>{statement.invoice_date}</td>
                            <td>
                              {statement.prefix}
                              {statement.invoice_no}
                            </td>
                            <td>{statement.customer_name}</td>
                            <td className="text-end">
                              {statement.total_tax_amount}
                            </td>
                            <td className="text-end">
                              {statement.gross_total_base}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </div>

            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              <Button
                type="button"
                className="bmt-btn-close itemsubmit mw-100"
                style={{ padding: "5px 25px" }}
                onClick={() => props.closeModal()}
              >
                Close
              </Button>
              <Button
                type="button"
                style={{ padding: "5px 25px" }}
                className="bmt-btn-submit itemsubmit mw-100"
                onClick={() => handleButtonClick("InvoiceDetail")}
              >
                Next
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
};

export { GstReturnSubmit };
