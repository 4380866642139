import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Card, CardBody, Col, Modal, Row } from "reactstrap";
import { post } from "src/helpers/api_helper";
import BootstrapTable from "react-bootstrap-table-next";

interface Props {
  modal_is_open: boolean;
  closeModal: any;
  data: any;
}
const ServicePricing = (props: Props) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const payload = {
        companyId: props.data.company_id,
        instance: props.data.instance_id,
      };
      const resp = await post(
        `${process.env.REACT_APP_API_URL}/api/cst_gst_services_list`,
        payload
      );
      if (resp.success) {
        setData(resp.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleUpdate = async (rowId: number, field: string, value: any) => {
    const updatedData = data.map(item =>
      item.id === rowId ? { ...item, [field]: value } : item
    );
    setData(updatedData);

    try {
      const payload = { gst_service_id: rowId, [field]: value, field: field };
      payload["companyId"] = props.data.company_id;
      payload["instance"] = props.data.instance_id;
      const response = await post(
        process.env.REACT_APP_API_URL + "/api/cst_update_gst_service",
        payload
      );
      if (response.success) {
        fetchData();
      }
    } catch (error) {
      console.error("Error updating data:", error);
      fetchData();
    }
  };

  const PriceEditor = ({
    value,
    row,
    name,
    onSave,
  }: {
    value: number;
    row: any;
    name: string;
    onSave: (newValue: any) => void;
  }) => {
    const [tempValue, setTempValue] = useState(value);
    const [isEditable, setIsEditable] = useState(false);

    const handleDoubleClick = () => {
      setIsEditable(true);
    };

    const handleBlur = () => {
      setIsEditable(false);
    };

    const handleChange = (e: any) => {
      setTempValue(e.target.value);
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        onSave(tempValue);
        setIsEditable(false);
      }
    };

    return (
      <div onDoubleClick={handleDoubleClick} className="mb-2">
        {isEditable ? (
          <input
            type="number"
            value={tempValue}
            className="form-control w-100 mb-2"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
          />
        ) : (
          <span>{name}{value}</span>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "",
      text: "SL No",
      formatter: (cellContent: any, row: any, rowIndex: number) => (
        <>{rowIndex + 1}</>
      ),
      editable: false,
    },
    {
      dataField: "service_name",
      text: "Service Name",
      editable: false,
    },
    {
      dataField: "rate",
      text: "Price",
      formatter: (cellContent: any, row: any) => (
        <>
          <PriceEditor
            value={row.rate}
            row={row}
            name={row.id === 1 ? "Monthly : " : ""}
            onSave={newValue => handleUpdate(row.id, "rate", newValue)}
          />
          {row.id === 1 && (
            <PriceEditor
              value={row.rate_2}
              row={row}
              name={"Quarterly : "}
              onSave={newValue => handleUpdate(row.id, "rate_2", newValue)}
            />
          )}
        </>
      ),
    },
  ];
  return (
    <Modal isOpen={props.modal_is_open} className={"md-700"}>
      <AvForm encType="multipart/formData" id="ticketForm">
        <div className="modal-header">
          <div className="text-center">
            <h5 className="modal-title mt-0" id="myModalLabel">
              GST Services
            </h5>
            <small>{props.data.company_name}</small>
          </div>
          <button
            type="button"
            onClick={() => props.closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            id="closeButton"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col xl={12}>
              <Row>
                <Col sm="12">
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={data}
                    columns={columns}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => props.closeModal()}
          >
            Close
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

export default ServicePricing;
