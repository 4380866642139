import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import Grid from "src/pages/Customers/Datatable";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import moment from "moment";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import ServicePricing from "./ServicePricing";

const Customers = () => {
  const [refresh, setRefresh] = useState(false);
  const [modal_is_open, set_modal_is_open] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const openModal = (data: any) => {
    setModalData(data);
    set_modal_is_open(true);
  };
  const closeModal = () => {
    set_modal_is_open(false);
  };
  const defaultSorted: any = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ];

  // Column data
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      hidden: true,
    },
    {
      text: "SL No",
      sort: true,
      formatter: (cellContent: any, row: any, rowIndex: any) => rowIndex + 1,
    },
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
    },
    {
      dataField: "first_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "company_email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "plan_name",
      text: "Plan",
      sort: true,
    },
    {
      dataField: "valid_from",
      text: "Valid From",
      sort: true,
    },
    {
      dataField: "valid_to",
      text: "Valid To",
      sort: true,
      formatter: (cellContent: any, row: any) => {
        if (row.plan_id == 1) {
          return "N/A";
        } else {
          return <>{cellContent}</>;
        }
      },
    },
    {
      dataField: "plan_name",
      text: "Status",
      sort: true,
      formatter: (cellContent: any, row: any) => {
        let status;
        if (row.plan_id == 1) {
          status = (
            <>
              <span className="badge badge-soft-primary">Active</span>
            </>
          );
        } else if (moment(row.valid_to).isAfter(moment())) {
          status = (
            <>
              <span className="badge badge-soft-primary">Active</span>
            </>
          );
        } else {
          status = (
            <>
              <span className="badge badge-soft-danger">Expired</span>
            </>
          );
        }
        return <>{status}</>;
      },
    },
    {
      dataField: "docu_count",
      text: "Doc Count",
      sort: true,
      formatter: (cellContent: any, row: any) => {
        return (
          <>
            <span className="badge rounded-pill badge-soft-pink">{cellContent}</span>
          </>
        );
      },
    },
    {
      dataField: "actions",
      text: "Action",
      isDummyField: true,
      formatter: (cellContent: any, row: any) => {
        if (row.status !== 0) {
          return (
            <div className="table-button">
              <button className="round-btn">
                <UncontrolledDropdown className="mt-4 mt-sm-0">
                  <DropdownToggle tag="a">
                    <EllipsisIcon />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      to="#"
                      tag="span"
                      onClick={() => openModal(row)}
                    >
                      <i
                        className="fi fi-rs-tags"
                        data-toggle="tooltip"
                        data-placement="bottom"
                      />{" "}
                      GST Services
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </button>
            </div>
          );
        } else {
          return "";
        }
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customer</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Customers" breadcrumbItem="Customers" />
          <Row>
            <Row className="flex-row-reverse mb-2"></Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Grid
                        link="list_all_customer"
                        columns={columns}
                        defaultSorted={defaultSorted}
                        refresh={refresh}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {modal_is_open && (
        <ServicePricing
          modal_is_open={modal_is_open}
          closeModal={closeModal}
          data={modalData}
        />
      )}
    </React.Fragment>
  );
};

export default Customers;
