import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Col, Row } from 'reactstrap';
import NoDataIndication from '../Datatable/NoDataIndication';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { post } from 'src/helpers/api_helper';

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Props {
  link: string;
  defaultSorted: any;
  columns: any;
  refresh: any;
}

const Grid = (props: Props) => {
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState("order_no");
  const [sortOrder, setSortOrder] = useState("asc");
  const [data, setData] = useState<any>([]);


  const pageOptions: any = {
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    custom: true,
  };

  const fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(process.env.REACT_APP_API_URL + `/api/${props.link}`, param);
        if (resp.success) {
          setTotalSize(resp.data.count);
          setData(resp.data.results);
        }

      } catch (err) {
        console.error(err);
      }
    };

    sendGetRequest();
  }
  const handleTableChange = (type: string, param: ChangeProp) => {
    setSizePerPage(param.sizePerPage);
    fetchData({ page: param.page, searchText: "", sizePerPage: param.sizePerPage, sortField: sortField, sortOrder: sortOrder });

  }
  useEffect(() => {
    fetchData({ page: 1, searchText: "", sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder });
  }, [props.refresh])

  const { SearchBar } = Search;
  return (
    <React.Fragment>

      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            columns={props.columns}
            data={data}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <Col md="4">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        {/* <SearchBar {...toolkitProps.searchProps} /> */}
                        {/* <i className="bx bx-search-alt search-icon" /> */}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive ">
                      <BootstrapTable
                        // responsive                                  
                        remote
                        bordered={false}
                        striped={false}
                        defaultSorted={props.defaultSorted}
                        classes={"table gst-table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        onTableChange={handleTableChange}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        noDataIndication={() => <NoDataIndication />}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone
                        {...paginationProps}
                      />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone
                        {...paginationProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

    </React.Fragment>
  );
};

export default Grid;