import { inWords } from "./inWords";

export function padLeadingZeros(num: number, size: number) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  } 


  export function convertNumberToWords(value: number,currency: string, dtext: string): string {
    let arrAmount = value.toString().split('.');
    let words = '';    
    let num1 = Number(arrAmount[0]);
    let num2 = Number(arrAmount[1]);
    words +=  inWords(num1) + ` ${currency} `;
    if (arrAmount.length == 2 && num2 > 0) {
        words += ` And ` + inWords(num2) + ` ${dtext}`;
    } else {
        words += ` And Zero ${dtext}`;
    }
    words += ' Only';
    return words;
  }
export const number_format = (value: number | bigint): string  =>  new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);
export function getQuarterly(no: number){
  if(no == 1){
    return "Q1"
  }else if(no == 2){
    return "Q2"
  }else if(no == 3){
    return "Q3"
  }else if(no == 4){
    return "Q4"
  }
}
export function getMonthName(monthNumber: number): string {
  const months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  if (monthNumber < 1 || monthNumber > 12) {
    console.error(`Invalid month number: ${monthNumber}`);
    return '';
  }
  return months[monthNumber - 1];
}