import React, { useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  NavLink,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  CardText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import Grid from "src/Datatable";
import { Link } from "react-router-dom";
import moment from "moment";
import Details from "../Compliance/details";
import { GstReturnSubmit } from "./GstReturnSubmit";
import { post } from "src/helpers/api_helper";
import Status from "./Status";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import Documents from "./Documents";
import { getMonthName, getQuarterly } from "src/helpers/common";
import SweetAlert from "react-bootstrap-sweetalert";

const GSTReturn = () => {
  const [activeTab, setactiveTab] = useState("1");
  const [modal_is_open, set_modal_is_open] = useState(false);
  const [submitModal, setSubmitModal] = useState<boolean>(false);
  const [activeTaxPeriod, setTaxPeriod] = useState<any>(null);
  const [activeYear, setYear] = useState<any>(null);
  const [order_id, setOrderId] = useState<string>("");
  const [instance, setInstanceId] = useState<string>("");
  const [modalData, setModalData] = useState<any>();
  const [showOptions, setshowOptions] = useState<any>();
  const [refresh, setrefresh] = useState<boolean>(false);
  const [popData, setPopData] = useState<any>(null);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const openModal = (data: any) => {
    setModalData(data);
    set_modal_is_open(true);
  };
  const closeModal = () => {
    set_modal_is_open(false);
  };
  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const handleRefresh = () => {
    setrefresh(prev => !prev);
  };
  const opneSubmitModal = (data: any) => {
    setTaxPeriod(data.tax_period);
    setYear(data.year);
    setSubmitModal(true);
    setOrderId(data.id);
    setInstanceId(data.instance_id);
  };
  const closeSubmitModal = () => {
    setTaxPeriod(null);
    setYear(null);
    setSubmitModal(false);
    setOrderId("");
    setInstanceId("");
  };
  const uploadModal = (data: any) => {
    setIsUploadModal(true);
    setPopData(data);
  };
  const CancelService = (data: any) => {
    setIsShow(true);
    setPopData(data);
  };
  const ConfirmCancel = () => {
    setIsShow(false);
    let postValues = {
      order_id: popData.id,
      data: 6,
    };
    const sendGetReturn = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/cancelled_order`,
          postValues
        );
        if (resp.success == true) {
          handleRefresh();
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetReturn();
  };
  const defaultSorted: any = [
    {
      dataField: "order_no",
      order: "desc",
    },
  ];
  const padLeadingZeros = (num: number, size: number) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  const colPeriod = (
    tax_period: any,
    period_frequency: any,
    year: any,
    service: any
  ) => {
    if (service == 1) {
      if (period_frequency == 2) {
        return year + " " + getQuarterly(tax_period);
      } else {
        return getMonthName(tax_period) + " " + year;
      }
    } else if (service == 3) {
      return year;
    } else {
      return <div>--</div>;
    }
  };

  const handleOptionChange = async (row_id: any, selectedValue: any) => {
    let postValues = {
      order_id: row_id,
      data: selectedValue,
    };
    const sendGetReturn = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/change_status`,
          postValues
        );
        if (resp.success == true) {
          handleRefresh();
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetReturn();
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      hidden: true,
    },
    {
      dataField: "tax_period",
      text: "Period",
      sort: true,
      formatter: (cellContent: number, row: any) => {
        return (
          <>
            {colPeriod(
              row.tax_period,
              row.period_frequency,
              row.year,
              row.service_id
            )}
          </>
        );
      },
    },
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
      formatter: (cellContent: any, row: any) => {
        return (
          <>
            {cellContent} <br />
            <small>RTN{padLeadingZeros(row.order_no, 5)}</small>
          </>
        );
      },
    },
    {
      dataField: "status",
      sort: true,
      formatter: (cellContent: number, row: any) => {
        return (
          <Status
            row_id={row.id}
            active_id={showOptions}
            case={1}
            cellContent={cellContent}
            handleOptionChangeFromParent={handleOptionChange}
          />
        );
      },
    },
    {
      dataField: "status",
      sort: true,
      formatter: (cellContent: number, row: any) => {
        return (
          <Status
            row_id={row.id}
            active_id={showOptions}
            case={2}
            cellContent={cellContent}
            handleOptionChangeFromParent={handleOptionChange}
          />
        );
      },
    },
    {
      dataField: "status",
      sort: true,
      formatter: (cellContent: number, row: any) => {
        return (
          <Status
            row_id={row.id}
            active_id={showOptions}
            case={3}
            cellContent={cellContent}
            handleOptionChangeFromParent={handleOptionChange}
          />
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent: number, row: any) => {
        return (
          <Status
            row_id={row.id}
            active_id={showOptions}
            case={4}
            cellContent={cellContent}
            handleOptionChangeFromParent={handleOptionChange}
          />
        );
      },
    },
    {
      dataField: "status",
      sort: true,
      formatter: (cellContent: number, row: any) => {
        return (
          <Status
            row_id={row.id}
            active_id={showOptions}
            case={5}
            cellContent={cellContent}
            handleOptionChangeFromParent={handleOptionChange}
          />
        );
      },
    },
    {
      dataField: "status",
      sort: true,
      formatter: (cellContent: number, row: any) => {
        return (
          <Status
            row_id={row.id}
            active_id={showOptions}
            case={6}
            cellContent={cellContent}
            handleOptionChangeFromParent={handleOptionChange}
          />
        );
      },
    },
    {
      dataField: "actions",
      text: "View",
      isDummyField: true,
      formatter: (cellContent: any, row: any) => {
        if (row.status !== 0) {
          return (
            <div className="table-button">
              <button className="round-btn">
                <UncontrolledDropdown className="mt-4 mt-sm-0">
                  <DropdownToggle tag="a">
                    <EllipsisIcon />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      to="#"
                      tag="span"
                      onClick={() => openModal(row)}
                    >
                      <i
                        className="fas fa-eye"
                        data-toggle="tooltip"
                        data-placement="bottom"
                      />{" "}
                      View
                    </DropdownItem>
                    <DropdownItem
                      to="#"
                      tag="span"
                      onClick={() => opneSubmitModal(row)}
                    >
                      <i
                        className="far fa-file"
                        data-toggle="tooltip"
                        data-placement="bottom"
                      />{" "}
                      Submitted Invoice
                    </DropdownItem>
                    <DropdownItem
                      to="#"
                      tag="span"
                      onClick={() => uploadModal(row)}
                    >
                      <i
                        className="fi fi-rr-folder-open"
                        data-toggle="tooltip"
                        data-placement="bottom"
                      />{" "}
                      Documents
                    </DropdownItem>
                    {row.status < 3 && (
                      <DropdownItem
                        to="#"
                        tag="span"
                        onClick={() => CancelService(row)}
                        className="text-danger"
                      >
                        <i
                          className="fi fi-sr-document-circle-wrong"
                          data-toggle="tooltip"
                          data-placement="bottom"
                        />{" "}
                        Cancel
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </button>
            </div>
          );
        } else {
          return "";
        }
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>GST Return</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="GST Return" breadcrumbItem="GST Return" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Grid
                    link="gst_return_order"
                    columns={columns}
                    defaultSorted={defaultSorted}
                    refresh={refresh}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {modal_is_open && (
        <Details
          modal_is_open={modal_is_open}
          closeModal={closeModal}
          data={modalData}
        />
      )}
      {submitModal && (
        <GstReturnSubmit
          isOpen={submitModal}
          tax_period={activeTaxPeriod}
          year={activeYear}
          order_id={order_id}
          instance={instance}
          closeModal={() => closeSubmitModal()}
          refresh={() => handleRefresh()}
        />
      )}
      {isUploadModal && (
        <Documents
          isOpen={isUploadModal}
          toggle={() => setIsUploadModal(false)}
          data={popData}
        />
      )}
      <SweetAlert
        show={isShow}
        title="Cancel"
        type="warning"
        onConfirm={() => {
          ConfirmCancel();
        }}
        onCancel={() => {
          setIsShow(false);
        }}
        showConfirm={true}
        showCancel={true}
        confirmBtnCssClass="bmt-btn-submit itemsubmit"
        cancelBtnCssClass="bmt-btn-close itemsubmit"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
      >
        Make sure that you want to cancel.
      </SweetAlert>
    </React.Fragment>
  );
};

export default GSTReturn;
