import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";

interface Params {
  row_id: string;
  active_id: string;
  case: number;
  cellContent: number;
  handleOptionChangeFromParent: (row_id: string, selectedValue: string) => void;
}

type State = {
  row_id: string;
  case: number;
  active_id: string | null;
  cellContent: number;
};

class Status extends Component<Params & RouteComponentProps, State> {
  constructor(props: Params & RouteComponentProps) {
    super(props);
    this.state = {
      row_id: props.row_id,
      case: props.case,
      active_id: props.active_id,
      cellContent: props.cellContent,
    };
  }

  arrStatus: string[] = [
    "Not Submit",
    "Not Assigned",
    "Assigned",
    "GSTR1",
    "GSTB3",
    "Closed",
  ];
  componentDidUpdate(prevProps: Params) {
    if (
      prevProps.row_id !== this.props.row_id ||
      prevProps.active_id !== this.props.active_id ||
      prevProps.cellContent !== this.props.cellContent
    ) {
      this.setState({
        row_id: this.props.row_id,
        active_id: this.props.active_id,
        cellContent: this.props.cellContent,
      });
    }
  }

  handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { row_id } = this.state;
    const selectedValue = event.target.value;
    this.setState({ active_id: "" });
    if(selectedValue !== ""){
      this.props.handleOptionChangeFromParent(row_id, selectedValue);
    }
  };

  handleClose = () => {
    this.setState({ active_id: "" });
  };

  handleDoubleClick = () => {
    this.setState({ active_id: this.state.row_id });
  };

  render() {
    const { cellContent, active_id, row_id } = this.state;

    return (
      <React.Fragment>
        {row_id === active_id ? (
          <div className="d-flex">
            {(() => {
              switch (this.state.case) {
                case 3:
                  return (
                    <select
                      className="form-control p-2"
                      value={cellContent || ""}
                      onChange={this.handleOptionChange}
                    >
                      <option value="1">Not Assigned</option>
                      <option value="2">Assigned</option>
                    </select>
                  );
                case 4:
                  return (
                    <select
                      className="form-control p-2"
                      value={cellContent || ""}
                      onChange={this.handleOptionChange}
                    >
                      <option value=" "> Select</option>
                      <option value="3">GSTR1 Filed</option>
                    </select>
                  );
                case 5:
                  return (
                    <select
                      className="form-control p-2"
                      value={cellContent || ""}
                      onChange={this.handleOptionChange}
                    >
                      <option value=" "> Select</option>
                      <option value="4">GSTB3 Filed</option>
                    </select>
                  );
                default:
                  return "";
              }
            })()}
            <button
              className="btn btn-danger p-0"
              onClick={this.handleClose}
            >
              <i className="bx bx-x"></i>
            </button>
          </div>
        ) : (
          <div>
            {(() => {
              switch (this.state.case) {
                case 1:
                  return <span className="text-success">PAID</span>;
                case 2:
                  return (
                    <span
                      className={
                        cellContent === 0 ? "text-danger" : "text-success"
                      }
                    >
                      {cellContent === 0 ? "Not Submitted" : "Submitted"}
                    </span>
                  );
                case 3:
                  return (
                    <span
                      className={
                        cellContent < 1
                          ? "text-grey"
                          : cellContent === 1 || cellContent== 6
                          ? "text-danger cursor-pointer"
                          : "text-success"
                      }
                      onDoubleClick={() => {
                        if (cellContent === 1) {
                          this.handleDoubleClick();
                        }
                      }}
                    >
                      {cellContent < 1 || cellContent== 6
                        ? "Assign"
                        : cellContent === 1
                        ? "Not Assigned"
                        : "Assigned"}
                    </span>
                  );
                case 4:
                  return (
                    <span
                      className={
                        cellContent < 2
                          ? "text-grey"
                          : cellContent === 2 || cellContent== 6
                          ? "text-danger cursor-pointer"
                          : "text-success"
                      }
                      onDoubleClick={() => {
                        if (cellContent === 2) {
                          this.handleDoubleClick();
                        }
                      }}
                    >
                      {cellContent < 2 || cellContent== 6
                        ? "GSTR1"
                        : cellContent === 2
                        ? "GSTR1 in Work"
                        : "GSTR1 Filed"}
                    </span>
                  );
                case 5:
                  return (
                    <span
                      className={
                        cellContent < 3
                          ? "text-grey"
                          : cellContent === 3 || cellContent== 6
                          ? "text-danger cursor-pointer"
                          : "text-success"
                      }
                      onDoubleClick={() => {
                        if (cellContent === 3) {
                          this.handleDoubleClick();
                        }
                      }}
                    >
                      {cellContent < 3 || cellContent== 6
                        ? "GSTR3B"
                        : cellContent === 3
                        ? "GSTR3B in Work"
                        : "GSTR3B Filed"}
                    </span>
                  );
                case 6:
                  return (
                    <span
                      className={
                        cellContent < 4
                          ? "text-grey"
                          : cellContent === 4
                          ? "text-danger cursor-pointer"
                          : cellContent === 6
                          ? "text-danger"
                          : "text-success"
                      }
                      onDoubleClick={() => {
                        if (cellContent === 4) {
                          this.handleDoubleClick();
                        }
                      }}
                    >
                      {cellContent < 4
                        ? "COMPLETED"
                        : cellContent === 4
                        ? "COMPLETED"
                        : cellContent === 6
                        ? "CANCELLED"
                        : "COMPLETED"}
                    </span>
                  );
                default:
                  return "";
              }
            })()}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Status);
