import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Modal, UncontrolledAlert, Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getAccessToken, post } from "src/helpers/api_helper";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  data: any;
}
interface FileType {
  name: string;
}
const Documents = (props: Props) => {
  const [isloading, setIsLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(true);
  const [OpenUploadModal, setOpenUploadModal] = useState(false);
  const [downloadingFiles, setDownloadingFiles] = useState<
    Record<number, boolean>
  >({});
  const [isHideSuccessMgs, setIsHideSuccessMgs] = useState(true);
  const [isHideErrorMgs, setIsHideErrorMgs] = useState(true);
  const [isModalProgress, setIsModalProgress] = useState(false);
  const [files, setFiles] = useState<FileType[]>([]);
  const [errorMgs, setErrorMgs] = useState(
    "Something went wrong, Please try again"
  );
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [downloadFiles, setDownloadFiles] = useState<any[]>([]);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
    {}
  );

  const { companies } = useSelector((state: any) => ({
    companies: state.companies.companies,
  }));
  const handleFileChange = (e: any) => {
    const selectedFiles: any = Array.from(e.target.files);
    setFiles(selectedFiles);
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.backgroundColor = "#e9f7ff"; // Change background on drag over
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.backgroundColor = "#f9f9f9"; // Revert background when drag leaves
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles: any = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles);
    e.target.style.backgroundColor = "#f9f9f9"; // Revert background after drop
  };
  const handleValidSubmit = async (event: any, values: any) => {
    setIsModalProgress(true);
    setIsHideSuccessMgs(true);
    setIsHideErrorMgs(true);

    const userForm: any = document.getElementById("ticketForm");
    const files: File[] = Array.from(userForm.files.files);
    const token = getAccessToken();
    setUploadProgress({});
    let success = true;

    for (const file of files) {
      const formData = new FormData();
      formData.append("id", props.data.id);
      formData.append("service_id", props.data.service_id);
      formData.append("files[]", file);
      try {
        await axios.post(
          process.env.REACT_APP_API_URL + "/api/save_gst_files",
          formData,
          {
            headers: { Authorization: token },
            onUploadProgress: progressEvent => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(prev => ({
                ...prev,
                [file.name]: progress > 1 ? progress : 1,
              }));
            },
          }
        );
      } catch (error) {
        setErrorMgs(`Error uploading file: ${file.name}`);
        setIsHideErrorMgs(false);
        success = false;
      }
    }
    if (success) {
      setIsHideSuccessMgs(false);
      setFiles([]);
      setOpenUploadModal(false);
      fetchFile();
      setTimeout(() => {
        setIsHideSuccessMgs(true);
        setUploadProgress({});
      }, 1500);
    }
    setIsModalProgress(false);
  };
  const removeFile = async (id: string, index: number) => {
    const conf = window.confirm("Do you want to delete this file?");
    if (conf) {
      try {
        const resp: any = await post(
          process.env.REACT_APP_API_URL + "/api/gst_rm_file",
          { id, index }
        );
        if (resp.success === true) {
          fetchFile();
        } else {
          setIsHideErrorMgs(false);
          setErrorMgs("Failed to remove the file. Please try again.");
        }
      } catch (error) {
        setIsHideErrorMgs(false);
        setErrorMgs("An error occurred while removing the file.");
      }
    }
  };
  const downloadFile = async (name: string, id: string, index: number) => {
    try {
      setDownloadingFiles(prev => ({ ...prev, [index]: true }));
      const resp: any = await post(
        process.env.REACT_APP_API_URL + "/api/download_file",
        { id, index }
      );
      if (resp.success === true) {
        const link = document.createElement("a");
        link.href = `data:application/octet-stream;base64,${resp.file_content}`;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadingFiles(prev => ({ ...prev, [index]: false }));
      } else {
        setIsHideErrorMgs(false);
        setDownloadingFiles(prev => ({ ...prev, [index]: false }));
      }
    } catch (error) {
      setIsHideErrorMgs(false);
      setDownloadingFiles(prev => ({ ...prev, [index]: false }));
      setErrorMgs("");
    }
  };
  useEffect(() => {
    fetchFile();
  }, [props.isOpen]);
  const fetchFile = async () => {
    try {
      var id = props.data.id;
      setIsLoading(false);
      setUploadedFiles([]);
      setDownloadFiles([]);
      const resp: any = await post(
        process.env.REACT_APP_API_URL + "/api/get_upload_files",
        { id }
      );
      if (resp.success === true) {
        const newFiles = resp.uploadfile.map((file: any) => ({
          id: file.id,
          name: file.file_name,
          date: file.created_at,
        }));
        const newFiles1 = resp.downloadfile.map((file: any) => ({
          id: file.id,
          name: file.file_name,
          date: file.created_at,
        }));
        setUploadedFiles(prev => [...prev, ...newFiles]);
        setDownloadFiles(prev => [...prev, ...newFiles1]);
        setIsLoading(true);
      } else {
        setIsHideErrorMgs(false);
        setErrorMgs("Failed to remove the file. Please try again.");
      }
    } catch (error) {
      setIsHideErrorMgs(false);
      setErrorMgs("An error occurred while removing the file.");
    }
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        role="dialog"
        className="md-800 modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="pt-3 px-4">
            <ul className="nav nav-pills nav-justified border">
              <li className="nav-item">
                <a
                  className={`nav-link ${isUpload ? "active" : ""}`}
                  onClick={() => setIsUpload(true)}
                >
                  Upload
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${!isUpload ? "active" : ""}`}
                  onClick={() => setIsUpload(false)}
                >
                  Download
                </a>
              </li>
            </ul>
          </div>
          {isUpload ? (
            <div className="modal-body border-0">
              <div className="row">
                <div className="col-md-12 text-end">
                  <Link to="#" onClick={() => setOpenUploadModal(true)}>
                    <button className="btn btn-round mx-3">
                      <i className="fi fi-br-upload"></i>
                    </button>
                  </Link>
                </div>
                <div className="col-md-12">
                  {isloading ? (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>File Name</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uploadedFiles.map((file, index) => (
                          <tr>
                            <td className="p-1">{index + 1}</td>
                            <td className="p-1">{file.name}</td>
                            <td className="p-1">
                              {moment(file.data).format("YYYY-MM-DD")}
                            </td>
                            <td className="p-1 text-end">
                              {downloadingFiles[index] ? (
                                <i
                                  className="fa fa-spinner fa-spin mx-2"
                                  style={{ color: "green" }}
                                ></i>
                              ) : (
                                <Link
                                  to="#"
                                  className="mx-2"
                                  onClick={() =>
                                    downloadFile(file.name, file.id, index)
                                  }
                                >
                                  <i
                                    className="fas fa-download"
                                    style={{ color: "green" }}
                                  ></i>
                                </Link>
                              )}
                              <Link
                                to="#"
                                className="mx-2"
                                onClick={() => removeFile(file.id, index)}
                              >
                                <i
                                  className="fas fa-trash-alt"
                                  style={{ color: "red" }}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                    </>
                  )}
                  <div className="d-block text-center mt-4">
                    <Button
                      type="button"
                      className="bmt-btn-close itemsubmit mw-100"
                      onClick={props.toggle}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-body border-0">
              {isloading ? (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>File Name</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {downloadFiles.map((file, index) => (
                      <tr>
                        <td className="p-1">{index + 1}</td>
                        <td className="p-1">{file.name}</td>
                        <td className="p-1">
                          {moment(file.data).format("YYYY-MM-DD")}
                        </td>
                        <td className="p-1 text-end">
                          {downloadingFiles[index] ? (
                            <i
                              className="fa fa-spinner fa-spin mx-2"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <Link
                              to="#"
                              className="mx-2"
                              onClick={() =>
                                downloadFile(file.name, file.id, index)
                              }
                            >
                              <i
                                className="fas fa-download"
                                style={{ color: "green" }}
                              ></i>
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                </>
              )}
              <div className="d-block text-center mt-4">
                <Button
                  type="button"
                  className="bmt-btn-close itemsubmit mw-100"
                  onClick={props.toggle}
                >
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={OpenUploadModal}
        role="dialog"
        className="modal-dialog-centered"
      >
        <AvForm
          onValidSubmit={handleValidSubmit}
          encType="multipart/form-data"
          id="ticketForm"
        >
          <div className="modal-content">
            <UncontrolledAlert hidden={isHideSuccessMgs} color="success">
              Documents saved successfully.
            </UncontrolledAlert>
            <UncontrolledAlert hidden={isHideErrorMgs} color="danger">
              {errorMgs}
            </UncontrolledAlert>
            <div className="modal-body border-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <div
                      className="file-upload-container d-flex justify-content-center"
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      <label
                        htmlFor="files"
                        className="text-center font-size-16 my-2"
                      >
                        {files.length > 0 ? (
                          files.map((file, index) => (
                            <>
                              <span className="font-size-12" key={index}>
                                {file.name}
                              </span>{" "}
                              <br />
                            </>
                          ))
                        ) : (
                          <>
                            <i className="fi fi-rr-folder-upload"></i>
                            <br />
                            Drag & Drop Files Here
                          </>
                        )}
                      </label>
                      <AvField
                        name="files"
                        id="files"
                        type="file"
                        multiple
                        accept=".pdf, .csv, .xls, .xlsx"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  {Object.keys(uploadProgress).length > 0 && (
                    <div className="mt-3">
                      <h6>Upload Progress:</h6>
                      <ul className="list-unstyled">
                        {Object.entries(uploadProgress).map(
                          ([fileName, progress]) => (
                            <li key={fileName} className="my-2">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>{fileName}</span>
                                <span>{progress}%</span>
                              </div>
                              <Progress value={progress} />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  <div className="d-block text-center mt-4">
                    {isModalProgress ? (
                      <Button
                        type="submit"
                        className="bmt-btn-submit"
                        disabled={true}
                      >
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        Submitting
                      </Button>
                    ) : (
                      <>
                        <Button
                          type="button"
                          className="bmt-btn-close itemsubmit mw-100"
                          onClick={() => setOpenUploadModal(false)}
                        >
                          Close
                        </Button>{" "}
                        <Button
                          type="submit"
                          className="bmt-btn-submit itemsubmit mw-100"
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default Documents;
